import NextImage from "next/legacy/image";
import { cloudflareLoader } from '/lib/services/helpers/cloudflareLoader';

const Image = ({ ...props }) => {

    if (process.env.NEXT_PUBLIC_WIGZO_ENV == 'dev') {
        return <NextImage {...props} />
    }

    return (
        <NextImage {...props} loader={cloudflareLoader} />
    )
}

export default Image